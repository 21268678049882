<template>
<!--    <div class="container bodywrap-content">-->
    <el-scrollbar class="container bodywrap-content" style="height: 100%;" :native="false">
        <div style="width: 1220px;margin: 0 auto">
            <h1 class="course-manage-title ml0">公开课</h1>
            <div class="res-container row">
                <div class="res-leftarea">
                    <div class="res-list">
                        <div class="video-box">
                            <video id="videoIns" width="100%" height="100%" :src="videoSrc" autoplay controls></video>
                        </div>
                        <div class="pub-video-info">
                            <div class="pub-name">课程讲解：{{publicClass.course_name}}</div>
                        </div>
                        <div class="pub-course-info">
                            <div class="c-content-title">课程内容</div>
                            <div class="c-public-content">
                                <p>
                                    <span style="font-family: 微软雅黑, sans-serif; font-size: 14px;">{{publicClass.course_desc}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <RecommendCourse></RecommendCourse>
            </div>
        </div>
<!--    </div>-->
    </el-scrollbar>
</template>

<script>
    import RecommendCourse from 'components/qualityCourse/RecommendCourse';
    export default {
        name: "PublicClassDetail",
        components: {
            RecommendCourse
        },
        data() {
            return {
                publicClassId: Number(this.$route.query.id) || '',
                publicClass: {
                    course_id: '',
                    course_name: '',
                    course_desc: '',
                },
                videoSrc: ""
            }
        },
        created() {
            this.getCourseInfo();
        },
        watch: {
            '$route'(to, from) {
                this.publicClassId = to.query.id;
                this.getCourseInfo();
            }
        },
        methods: {
            getCourseInfo() {
                this.$shttp.axiosGetBy(this.$api.courseContent,{id: this.publicClassId}, (res) => {
                    if (res.code === 200) {
                        this.publicClass = res.data;
                        let tmp = this.$common.filterResource(res.data.course_content, res.data.reslist, res.data.papers ,res.data.projects);
                        this.videoSrc = tmp[0].children[0].children[0].file_preview_path;
                    } else {
                        this.$message({
                            type: 'warning',
                            message: res.msg,
                            duration: 1000,
                            onClose: () => {
                                this.$router.push('/user/login');
                            }
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .quality-course-container {
        .bodywrap-content {
            /*min-height: 802px;*/
            box-sizing: border-box;
            /*margin: 0 auto;*/
            /*height: calc(100% - 84px) !important;*/
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .container {
            /*width: 1220px;*/
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
            .course-manage-title {
                color: #595959;
                font-size: 18px;
                margin: 15px 0 0;
                width: 1000px;
                font-weight: bold;
            }
            .row {
                display: flex;
                flex-wrap: wrap;
            }
            .res-container {
                display: flex;
                justify-content: start;
                margin-left: 0;
                .res-leftarea {
                    width: 920px;
                    margin: 0;
                    .res-list {
                        .video-box {
                            margin: 15px 0 0 5px;
                            width: 916px;
                            height: 100%;
                        }
                        .pub-video-info .pub-name {
                            color: #333;
                            font-size: 20px;
                            padding: 15px 0;
                            border-bottom: 1px solid #E0E0E1;
                            margin: 0 5px;
                        }
                        .pub-course-info {
                            margin: 30px 5px 60px 5px;
                            .c-content-title {
                                font-size: 16px;
                                color: #666;
                                margin-bottom: 30px;
                                &::before {
                                    content: '';
                                    display: inline-block;
                                    width: 3px;
                                    height: 16px;
                                    background: #E31D1A;
                                    margin: 0 10px 0 0;
                                    vertical-align: -2px;
                                }
                            }
                            .c-public-content {
                                color: #666666;
                                line-height: 24px;
                                p {
                                    margin-top: 0;
                                    margin-bottom: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>